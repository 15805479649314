<template>
  <div>

    <b-carousel class="carousel-1-normal" v-if="foto_azienda !== null && foto_azienda.length > 0"
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="photo in foto_azienda"
                        :key="photo.id"
                        :img-src="photo.img"
      ></b-carousel-slide>
    </b-carousel>
    <!-- '-/stretch/fill/-/format/auto/'" -->

    <div :style="{'background-color': sfondo_3 + '!important'}">
      <div class="container-80 info-who-we-are" :style="{'background-color': sfondo_3 + '!important'}">
        <div class="who-we-are">
          <div class="title-who-we-are">
            <h1 :style="{'color': testo_sfondo_3 + '!important'}">{{ nome_azienda }}</h1>
          </div>

          <div :style="{'color': testo_sfondo_3 + '!important'}" class="text-color-who-we-are">
            <span :style="{'color': testo_sfondo_3 + '!important'}" id="description-chi-siamo"
                  v-html="chi_siamo"></span>
          </div>
        </div>
        <div class="section-other-image-agency"
             v-if="foto_azienda !== null && foto_azienda.length > 0"
        >
          <b-row>
            <b-col md="4" v-for="item in foto_azienda" :key="item.id">
              <div
                  class="other-img-agency"
                  :style="{ backgroundImage: `url('${item.img} ')` }"></div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
        v-if="servizi.length !== 0"
        class="services"
        :style="{'background-color': sfondo_2 }"
    >
      <div class="container-80">
        <h2 class="title-services" :style="{'color': testo_sfondo_2 + '!important'}">Servizi</h2>
        <b-row class="all-services">
          <b-col md="2" class="item-services" v-for="(value, propertyName) in servizi" :key="propertyName">
            <!-- <img class="logo-services" :src="findIconServizio(propertyName)" alt=""> -->

            <object type="image/svg+xml"
                    :style="{'color': testo_sfondo_2 }"
                    class="logo-services-altair"
                    :data="findIconServizio(propertyName)">
            </object>


            <h4 class="title-service" :style="{'color': testo_sfondo_2 + '!important'}">{{ propertyName }}</h4>
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_2 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Servizi Mobile -->
      <div class="d-md-none">
        <div :class="{ active_services : active_el === index }" class="img-center"
             v-for="(value, propertyName, index) in servizi" :key="propertyName" :id="'servizio'+index">

          <!-- <img :src="findIconServizio(propertyName)" class="logo-services"> -->
          <object type="image/svg+xml"
                  class="logo-services-altair"
                  :data="findIconServizio(propertyName)">
          </object>

          <div class="item-services-mobile">
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_2 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </div>
          <div class="btn btn-prev" aria-label="Previous slide" @click="activate(index - 1)">
            &#10094;
          </div>
          <div class="btn btn-next" aria-label="Next slide" @click="activate(index + 1)">
            &#10095;
          </div>
        </div>
      </div>
      <!-- /Servizi Mobile -->
    </div>
  </div>
</template>


<script>

import {mapGetters} from 'vuex'
import {iconaServizio} from "@/utils/util";

export default {
  name: "ChiSiamo",

  data() {

    return {
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      slide: 0,
      sliding: null,
      active_el: 0,
    }
  },

  computed: {

    ...mapGetters([
      'chi_siamo',
      'nome_azienda',
      'cap',
      'telefono',
      'comune',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'texture',
      'email',
      'template',
      'geolocation',
      'icone',
      'sedi',
      'servizi',
      'foto_azienda',
    ]),
  },

  metaInfo() {
    return {
      title: 'Chi Siamo - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  methods: {

    activate: function (el) {
      if (el === 4) {
        el = 0;
      }
      if (el === -1) {
        el = 3;
      }
      this.active_el = el;
    },

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },
    findIconServizio(name) {
      return iconaServizio(name, this.icone)
    },
  },
}
</script>


<style scoped>
@import '~@/assets/css/template_altair/chi_siamo.css';
</style>
