<template>
  <div :style="{'background-color': sfondo_3 + '!important'}">

    <b-carousel class="carousel-1-normal" v-if="foto_azienda !== null && foto_azienda.length > 0"
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="photo in foto_azienda"
                        :key="photo.id"
                        :img-src="photo.img"
      ></b-carousel-slide>
    </b-carousel>

    <div class="container-80 info-who-we-are">
      <div class="who-we-are">
        <div class="title-who-we-are">
          <h1 :style="{'color': testo_sfondo_3 + '!important'}">{{ nome_azienda }}</h1>
        </div>

        <div :style="{'color': testo_sfondo_3 + '!important'}" class="text-color-who-we-are">
          <span id="description-chi-siamo" v-html="chi_siamo"></span>
        </div>
      </div>
      <div class="section-other-image-agency"
           v-if="foto_azienda !== null && foto_azienda.length > 0"
      >
        <b-row>
          <b-col md="4" v-for="item in foto_azienda" :key="item.id">
            <div
                class="other-img-agency"
                :style="{ backgroundImage: `url('${item.img} ')` }"></div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div
        v-if="servizi.length !== 0"
        class="services"
        :style="{'background-color': sfondo_3 }"
    >
      <div class="container-80">
        <h2 class="title-services" :style="{'color': testo_sfondo_3 + '!important'}">Servizi</h2>
        <b-row class="all-services">
          <b-col md="2" class="item-services" v-for="(value, propertyName) in servizi" :key="propertyName">
            <!-- <img class="logo-services" :src="findIconServizio(propertyName)" alt=""> -->

            <object type="image/svg+xml"
                    :style="{'background-color': sfondo_1 }"
                    class="logo-services"
                    :data="findIconServizio(propertyName)">
            </object>


            <h4 class="title-service" :style="{'color': testo_sfondo_3 + '!important'}">{{ propertyName }}</h4>
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Servizi Mobile -->
      <div class="d-md-none">
        <div :class="{ active_services : active_el === index }" class="img-center"
             v-for="(value, propertyName, index) in servizi" :key="propertyName" :id="'servizio'+index">

          <!-- <img :src="findIconServizio(propertyName)" class="logo-services"> -->
          <object type="image/svg+xml"
                  :style="{'background-color': sfondo_1 }"
                  class="logo-services"
                  :data="findIconServizio(propertyName)">
          </object>

          <div class="item-services-mobile">
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_2 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </div>
          <div class="btn btn-prev" aria-label="Previous slide" @click="activate(index - 1)">
            &#10094;
          </div>
          <div class="btn btn-next" aria-label="Next slide" @click="activate(index + 1)">
            &#10095;
          </div>
        </div>
      </div>
      <!-- /Servizi Mobile -->
    </div>
  </div>
</template>


<script>

import {mapGetters} from 'vuex'
import {iconaServizio} from "@/utils/util";

export default {
  name: "ChiSiamo",

  data() {

    return {
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      slide: 0,
      sliding: null,
      active_el: 0,
    }
  },

  computed: {

    ...mapGetters([
      'chi_siamo',
      'nome_azienda',
      'cap',
      'telefono',
      'comune',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'sfondo_3',
      'sfondo_2',
      'sfondo_1',
      'texture',
      'email',
      'template',
      'geolocation',
      'icone',
      'sedi',
      'servizi',
      'foto_azienda',
    ]),
  },

  metaInfo() {
    return {
      title: 'Chi Siamo - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {

    activate: function (el) {
      if (el === 4) {
        el = 0;
      }
      if (el === -1) {
        el = 3;
      }
      this.active_el = el;
    },

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },

    findIconServizio(name) {
      return iconaServizio(name, this.icone)
    },

    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("carousel-1").classList.add("carousel-1-scroll");
        document.getElementById("carousel-1").classList.remove("carousel-1-normal");
      } else {
        document.getElementById("carousel-1").classList.remove("carousel-1-scroll");
        document.getElementById("carousel-1").classList.add("carousel-1-normal");
      }
    },
  },
}
</script>


<style scoped>
.slider-margin {
  margin-top: 155px;
}

.background-img-homepage-chisiamo {
  width: 100%;
  margin-top: 155px;
}

.info-who-we-are {
  padding-top: 30px;
  padding-bottom: 60px !important;
}

.title-who-we-are {
  text-align: center;
  margin-bottom: 40px;
}

.text-color-who-we-are {
  column-count: 2;
  column-gap: 50px;
}

.who-we-are > h1 {
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
}

.other-img-agency {
  height: 250px;
  width: auto;
  margin-top: 20px;
  border-radius: 25px;
  background-position: center;
  background-size: contain, cover;
  background-repeat: no-repeat;
}

#description-chi-siamo {
  display: -webkit-box;
}

.title-services {
  text-align: center;
  padding-bottom: 40px;
  padding-top: 40px;
}

.all-services {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.logo-services {
  width: 100px;
  height: 100px;
  margin-bottom: 40px;
  border-radius: 25px;
}

.title-service {
  text-align: center;
}


@media (max-width: 767.98px) {
  .background-img-homepage-chisiamo {
    margin-top: 60px;
  }

  .title-who-we-are {
    margin-bottom: 0px;
  }

  .title-who-we-are > h1 {
    font-size: 25px;
  }

  .text-color-who-we-are {
    text-align: center;
    column-count: auto;
    column-gap: 0px;
    margin-bottom: 0px;
  }

  #read-all-desktop {
    display: none;
  }

  #read-all-mobile {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: end;
    color: #2D3384 !important;
  }

  .other-img-agency {
    height: 220px;
    margin-top: 0px;
    border-radius: 0px;
  }


  /*Sezione Servizi*/
  .all-services {
    display: none !important;
  }

  #servizio0, #servizio1, #servizio2, #servizio3 {
    display: none;
  }

  .active_services {
    display: block !important;
  }

  .img-center {
    position: relative;
    text-align: center;
  }

  .btn-prev, .btn-next {
    position: absolute;
  }

  .btn-prev {
    top: 7%;
    left: 10%;
  }

  .btn-next {
    top: 7%;
    right: 10%;
  }

  .item-services {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    align-items: baseline;
  }

  .item-services-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .item-services-mobile > span {
    margin-top: 5px;
    font-size: 14px;
    color: grey;
  }

  .title-service {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

}

</style>